<template>
  <div class="map">
    <div class="download-overlay" ref="downloadOverlay">
      <div class="download-overlay-inner" ref="downloadOverlayInner"></div>
    </div>
    <gmap-map
      :center="center"
      :zoom="12"
      style="width:100%;  height: 100%;"
      v-bind:options="mapStyle"
      ref="mapRef"
    >
<!--      POI Markers-->
      <div v-if="showPOIs">
        <gmap-marker
            :key="index"
            v-for="(m, index) in poiMarkers"
            :position="m.position"
            :title="m.title"
        ></gmap-marker>
    </div>
    </gmap-map>

<!--    Floating Settings Menu -->
    <div class="classification-control clickable">
      <b-dropdown text="Settings" variant="outline">
        <div class="settings-drop-down p-1">
          <b-dropdown-header id="dropdown-header-label">
            Data Date
          </b-dropdown-header>
          <b-dropdown-form>
            <b-form-select :options="dataDateOptions" id="lga" aria-placeholder="location" name="dataset"
                           placeholder="Select Location" class="dataset-date-select w-100"
                           v-model="selectedDatasetDate" @change="selectDatasetDate"
            > </b-form-select>
          </b-dropdown-form>

          <b-dropdown-divider>
          </b-dropdown-divider>
          <b-dropdown-header id="dropdown-header-label">
            Boundary Type
          </b-dropdown-header>
          <b-dropdown-form>
            <div v-for="(boundaryTypeOption, idx) of boundaryTypeOptions" v-bind:key="idx">
              <input type="radio" @change="changeBoundaryTypes" v-model="boundaryType" :value="boundaryTypeOption.value">
              {{boundaryTypeOption.text}}
            </div>
          </b-dropdown-form>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <b-dropdown-header id="dropdown-header-label">
          Dataset Aggregation
          </b-dropdown-header>
          <b-select :options="datasetAggregatorOptions" v-model="datasetAggregator" @change="changeDatasetAggregation">
          </b-select>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <b-dropdown-form>
            <div class="d-flex flex-column flex-nowrap">
              <span><input type="checkbox" v-model="showPOIs"> Show POIs</span>
              <span><input type="checkbox" :checked="this.showSA1Names" @click="toggleSA1Names"> Show SA1 Names</span>
            </div>
          </b-dropdown-form>
        </div>
      </b-dropdown>
    </div>

    <!--    Floating Dataset Control-->
    <div class="dataset-control clickable">
      <font-awesome-icon class="poi-disabled" icon="database"
                         v-b-tooltip title="Dataset Selected"></font-awesome-icon>
<!--      Dataset Filter -->
      <b-dropdown dropup :text="selectedDatasetShort" variant="outline" class="dataset-select"
                  @shown="onDatasetDropdown">
        <b-dropdown-form>
          <b-input-group>
            <b-input-group-prepend>
              <font-awesome-icon class="search-icon mr-1" icon="search" size="2x"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input type="search" placeholder="Filter Datasets" v-model="datasetFilterText"></b-form-input>

          </b-input-group>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
<!--        List of Dataset Options-->
        <div class="dataset-list">
          <b-dropdown-item-button v-for="(dataset, idx) in datasetOptions" v-bind:key="idx"
                                  :active="selectedDataset===dataset.value"
            @click="selectDataset(dataset.value)"
          >
            {{dataset.text}}
            <font-awesome-icon icon="check" v-if="selectedDataset===dataset.value"></font-awesome-icon>
          </b-dropdown-item-button>
        </div>
      </b-dropdown>
    </div>

<!--    Dataset Selected Indicator -->
    <div class="dataset-selected clickable reboundary-card" v-if="selectedDataset">
      <div class="dataset-selected-inner">
        <div>
          <div class="dataset-selected-heading">
              <font-awesome-icon class="dataset-selected-icon" icon="database"
                                 v-b-tooltip title="Dataset Selected"></font-awesome-icon>
            {{ selectedDatasetShort }}
          </div>

          <div><span class="dataset-selected-label">Dataset Date:</span> {{ selectedDatasetDate }}</div>
          <div><span class="dataset-selected-label">Data Format:</span>
            {{selectedDatasetFormat}}
            <font-awesome-icon v-if="datasetBoundaryTypeMismatch"
                               icon="exclamation-triangle"
                               class="data-mismatch-icon"
                               v-b-tooltip
                               :title="`Dataset Format (${selectedDatasetFormat}) and Boundary Type (${boundaryType}) ` +
                                  `are different. As long as you selected a larger boundary, we'll resample the data ` +
                                  `to fit the boundary (e.g. SA1 Data can be refit to a SA2 Boundary).`"
            ></font-awesome-icon>
          </div>
          <div>
            <span class="dataset-selected-label">Min/Max:</span> {{datasetMin}} / {{datasetMax}}
          </div>
          <div>
            <span class="dataset-selected-label">Mean:</span> {{datasetMean}}
          </div>
        </div>
        <div class="dataset-control-close" @click="clearDataset">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </div>
      </div>
    </div>
<!-- Boundary Search Box-->
    <div class="search-control">
      <b-input-group>
        <b-input-group-prepend>
          <font-awesome-icon class="search-icon mr-2" icon="map-marked-alt" size="2x"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input type="search" placeholder="Search Boundaries..." v-model="boundarySearchText"
                      debounce="250"
                      @focus="setShowSearchResults(true)"
                      @blur="setShowSearchResults(false)"
        ></b-form-input>

      </b-input-group>
    </div>

    <div class="search-results dropdown-menu" v-if="showSearchResults">
      <h5 class="">Search Results</h5>
      <div class="d-flex flex-row" v-if="boundarySearchLoading">
        <Loading :is_loading="boundarySearchLoading" size="2x" /> Searching...
      </div>
      <div v-if="!boundarySearchResults.length && !boundarySearchLoading">No Result</div>
      <b-dropdown-item-button class="w-100"
        v-for="(boundary, idx) of boundarySearchResults" v-bind:key="idx"
                              @click="selectBoundary(boundary)"
      >
        <font-awesome-icon icon="map-marker-alt" class="search-result-icon"></font-awesome-icon>
        {{boundary.name}} - {{boundary.classification}}<span v-if="boundary.minimum_applicable_date"> @ {{boundary.minimum_applicable_date.substring(0, 10)}}</span>

      </b-dropdown-item-button>

    </div>
  </div>
</template>

<script>
import BoundaryService from '../services/boundary.service'
import Loading from "./Loading.vue";


export default {
  name: 'ExplorerMap',
  components: {Loading},
  props: ['geojsondata', 'poidata'],
  data() {
    return {
      center: {lat: -38.3687, lng: 142.4982},
      startBounds: null,
      mapStyle: {
        styles: [
          {
            elementType: 'geometry',
            stylers: [{ saturation: -100 }]
          }
        ]
      },
      poiMarkers: [],
      map: null,
      showPOIs: false,
      boundaryTypeOptions: [
        {
          value: 'SA1',
          text: 'SA-1',
          enable: true
        },
        {
          value: 'SA2',
          text: 'SA-2',
          enable: false
        },
        {
          value: 'SA3',
          text: 'SA-3',
          enable: false
        },
        {
          value: 'SA4',
          text: 'SA-4',
          enable: false
        },
        {
          value: 'LGA',
          text: 'LGA',
          enable: false
        },
        {
          value: 'State',
          text: 'States',
          enable: false
        },
        {
          value: 'City',
          text: 'Cities',
          enable: false
        }
      ],
      viewChangeDebounceTimer: 0, // Holds last time the View change handler ran
      debounceAmount: 500, // Min time between view change executions (ms)
      boundaries: {},
      shapes: {},
      boundaryColors: {},
      lastLoadedBounds: null,
      textOverlay: null,
      infoWindows: {},
      downloadClearTimer: null,
      selectedDataset: null,
      selectedDatasetDate: '2021-01-01',
      showSA1Names: false,
      relativeDatasetRange: {
        min: 0,
        max: 0
      },
      // TODO - Rework the query language so we can query the same attribute with multiple operators at the same level
      boundaryDateFilters: {
        '2016-01-01': {
          'minimum_applicable_date': {
            'operation': 'gte',
            'value': `2016-01-01 00:00:00`,
            'type': 'datetime'
          },
          'and': {
            'minimum_applicable_date': {
              'operation': 'lte',
              'value': `2020-01-01 00:00:00`,
              'type': 'datetime'
            }
          }
        },
        '2021-01-01': {
          'minimum_applicable_date': {
            'operation': 'gte',
            'value': `2021-01-01 00:00:00`,
            'type': 'datetime'
          },
          'and': {
            'minimum_applicable_date': {
              'operation': 'lte',
              'value': `2024-01-01 00:00:00`,
              'type': 'datetime'
            }
          }
        }
      },
      datasetFormatLookup: {
        sa1_statistic: 'SA1',
        sa2_statistic: 'SA2',
        sa3_statistic: 'SA3',
        sa4_statistic: 'SA4'
      },
      datasetFilterText: '',
      datasetAggregator: 'sum',
      boundaryType: 'SA1',
      boundarySearchText: '',
      boundarySearchLoading: false,
      boundarySearchResults: [],
      showSearchResults: false
    }
  },
  computed: {
    datasetAggregatorOptions () {
      return Object.keys(this.$store.state.query.dataset_agg_funcs).map((key) => {
        return {
          value: key,
          text: this.$store.state.query.dataset_agg_funcs[key].text
        }
      })
    },
    /**
     * Generates a list of available Boundary Types to display
     * @return {(string|*)[]}
     */
    boundaryTypes () {
      return this.boundaryTypeOptions.filter(boundaryType => boundaryType.enable).
        map(boundaryType => boundaryType.value)
    },
    /**
     * Return a list of available Boundary/Dataset Dates
     * Note: This probably should be supplied by the server, instead of being static.
     * @return {[{text: string, value: string},{text: string, value: string}]}
     */
    dataDateOptions () {
      // TODO - The server should probably give us these options
      return [
        {
          text: '2016',
          value: '2016-01-01'
        },
        {
          text: '2021',
          value: '2021-01-01'
        }]
    },
    /**
     * Short Description of the currently selected Dataset
     * @return {*|string|string}
     */
    selectedDatasetShort() {
      if (this.$store.state.query.datasets) {
        return this.$store.state.query.selected_dataset ? this.$store.state.query.selected_dataset.human_name || this.$store.state.query.selected_dataset.name : 'Select Dataset'
      } else {
        return 'Loading Datasets...'
      }
    },
    /**
     * Returns a list of available datasets. Filtered by dataset date and any filter text currently present
     * @return {[{text: string, value: null}]|*}
     */
    datasetOptions () {
      if (this.$store.state.query.datasets) {
        let filteredDatasets = this.$store.state.query.datasets.filter(ds => ds.valid_dates.includes(this.selectedDatasetDate))

        if (this.datasetFilterText.length) {
          let filterTextLC = this.datasetFilterText.toLowerCase()
          filteredDatasets = filteredDatasets.filter(ds => {
            if (ds.human_name) {
              return ds.name.toLowerCase().includes(filterTextLC) || ds.human_name.toLowerCase().includes(filterTextLC)
            } else {
              return ds.name.toLowerCase().includes(filterTextLC)
            }
          })
        }

        return filteredDatasets.map((dataset) => {
          return {
            text: `${dataset.human_name || dataset.name}(${this.getDatasetFormatText(dataset)})`,
            value: dataset.dataset_id
          }
        }).concat({text: 'None', value: null})
      } else {
        return [{
          text: 'Loading Datasets',
          value: null
        }]
      }
    },
    /**
     * Returns the format of the currently selected dataset (e.g. SA1) formatted
     * @return {*|string}
     */
    selectedDatasetFormat () {
      if (this.$store.state.query.selected_dataset) {
        return this.getDatasetFormatText(this.$store.state.query.selected_dataset)
      } else {
        return ''
      }
    },
    datasetMin () {
      return this.$store.state.query.selected_dataset_min !== null ? this.$store.state.query.selected_dataset_min :  '-'
    },
    datasetMax () {
      return this.$store.state.query.selected_dataset_max || '-'
    },
    datasetMean () {
      if (this.$store.state.query.selected_dataset_mean !== null) {
        return this.$store.state.query.selected_dataset_mean.toFixed(2)
      } else {
        return '-'
      }
    },
    datasetBoundaryTypeMismatch() {
      return (this.selectedDatasetFormat && this.selectedDatasetFormat !== this.boundaryType)
    }
  },
  async mounted() {
    if (Object.hasOwn(this.$route.query, 'dataset')) {
      // Download the dataset, set the date, but don't attempt to draw yet.
      await this.selectDataset(this.$route.query.dataset, true, false)
    }

    if (Object.hasOwn(this.$route.query, 'boundary')) {
      // Download the dataset, set the date, but don't attempt to draw yet.
      await this.loadBoundaryId(this.$route.query.boundary)
    }

    // Map is loaded async, so we need to wait...
    this.$refs.mapRef.$mapPromise.then((map) => {
      // GeoJSON styling is decided by either a static value or a function
      map.data.setStyle((feature) => {
        // console.log(this.relativeDatasetRange)
        let id = feature.getId()  // Have to use a function to lookup the id
        let color = this.get_colour(id)
        let opacity = feature.getProperty('opacity')
        let visible = feature.getProperty('visible')
          return {
            fillColor: `rgba(${color[0]}, ${color[1]}, ${color[2]})`,
            strokeColor: `rgba(${color[0] + 10}, ${color[1] + 10}, ${color[2] + 10})`,
            strokeOpacity: 0.6 * opacity,
            fillOpacity: this.get_fill_opacity(id) * opacity,
            clickable: true,
            visible: visible
          }
      })
      if (this.startBounds) {
        map.fitBounds(this.startBounds)
      }
      // Add GeoJSON feature click event listener
      map.data.addListener('click', (event) => {
        this.clickBoundary(event)
      })
      map.data.addListener('mouseover', (event) => {
        map.data.overrideStyle(event.feature, {
          strokeWeight: 10,
          fillOpacity: 0.9
        })
        if (this.textOverlay) {
          // The listener is setup before the overlay is fully loaded
          this.textOverlay.showLabel(event.feature.getId())
        }
      })
      map.data.addListener('mouseout', (event) => {
        map.data.revertStyle();
        if (this.textOverlay) {
          this.textOverlay.resetLabel(event.feature.getId())
        }
      })
      map.addListener('idle', () => {
        // TODO - Triggering on the idle event only, seems to be the best option.
          this.mapViewChangeHandler()
      })
      this.map = map

      setTimeout(() => {
        this.mapViewChangeHandler()
        setInterval(() => {this.fadeInHandler()}, 50)

        // This is a bit of a weird way to access this module, but it allows it to be loaded AFTER google maps, so we
        // know the library is present when we import the module (which relies upon it)
        const textOverlayModule = () => import('./MapsTextOverlay')
        textOverlayModule().then((textOverlay) => {
          this.textOverlay = new textOverlay.TextOverlay(this.boundaries, this.map, this.boundaryColors, [this.boundaryType], this.showSA1Names)
        })
      }, 500)
    })
    if (!this.$store.state.query.datasets) {
      await this.$store.dispatch('query/getDatasets')
    }

    if (this.$store.state.query.selected_dataset_date === null) {
      await this.$store.dispatch('query/setSelectedDatasetDate', { date: this.selectedDatasetDate })
    }
  },
  methods: {
    async changeDatasetAggregation() {
      this.$store.commit('query/updateDatasetAggMethod', this.datasetAggregator )
      this.textOverlay.clear()
      this.clearRelativeDatasetRange()
      this.updateRelativeShading()
      this.mapViewChangeHandler(true)
    },
    /**
     * Turns dataset format properties into human readable format (e.g. sa1_format -> 'SA1'
     * @param ds
     * @return {*}
     */
    getDatasetFormatText(ds) {
      if (Object.hasOwn(this.datasetFormatLookup, ds.format)) {
        return this.datasetFormatLookup[ds.format]
      } else {
        return ds.format
      }
    },
    /**
     * Get the currently cached boundaries
     * @param boundaryArray
     */
    cacheBoundaries (boundaryArray) {
      boundaryArray.forEach(boundary => this.boundaries[boundary.boundary_id] = boundary)
    },
    /**
     * Get the Ids of the currently cached boundaries
     * @return {number[]}
     */
    getCachedBoundaryIds () {
      return Object.keys(this.boundaries).map(i => parseInt(i))
    },
    /**
     * Clear the boundary cache.
     */
    clearBoundaries () {
      this.clearShapes()
      this.clearInfoBoxes()
      this.textOverlay.clear()
      Object.keys(this.boundaries).forEach(key => delete this.boundaries[key])
      this.mapViewChangeHandler()
    },
    clearInfoBoxes() {
      Object.values(this.infoWindows).forEach(box => box.close())
      Object.keys(this.infoWindows).forEach(key => delete this.infoWindows[key])
    },
    clearShapes() {
      Object.values(this.shapes).forEach(shapeArray => shapeArray.forEach(feature => this.map.data.remove(feature)))
      Object.keys(this.shapes).forEach(key => delete this.shapes[key])
    },
    /**
     * Called when the map view changes. Identifies missing boundaries and queues them for loading
     * @param force
     * @return {Promise<void>}
     */
    async mapViewChangeHandler(force=false) {
      if ((performance.now() > (this.viewChangeDebounceTimer) + this.debounceAmount) || force) {
        let pageSize = 10
        // let start = performance.now()
        this.lastLoadedBounds = this.map.getBounds()
        this.viewChangeDebounceTimer = performance.now()
        let cachedIds = [...this.getCachedBoundaryIds()]
        let bounds = this.map.getBounds()
        let query = {
          'search_limit': pageSize,
          'include_shape': true,
          'boundary_id':
              {
                'operation': 'nin',
                'value': cachedIds
              },
          'boundary':
              {
                'operation': 'intersects',
                'value': bounds
              },
          'classification':
              {
                'operation': 'in',
                'value': [this.boundaryType]
              }
        }
        if (this.selectedDatasetFormat && this.selectedDatasetFormat !== this.boundaryType) {
          query['contained_boundary_class'] = this.selectedDatasetFormat
        }
        Object.assign(query, this.boundaryDateFilters[this.selectedDatasetDate])

        let resp = await BoundaryService.boundarySearch(query)
        let lastTotalResults = resp.data.n_results
        let boundariesTemp = resp.data.boundaries

        this.cacheBoundaries(resp.data.boundaries)
        if (this.selectedDataset) {
          this.updateRelativeShading()
        }
        this.loadGeoJSON()

        // If there are more pages available get them!
        // We're using boundary_id's to paginate, rather than row numbers (which doesn't work for some reason)
        let page_start = Math.max(...boundariesTemp.map(x => parseInt(x.boundary_id)))
        // Disable Movement while we're doing a big load. This helps to avoid chaining downloads.
        this.map.setOptions({draggable: false})

        if (boundariesTemp.length < lastTotalResults) {
          clearTimeout(this.downloadClearTimer)
          this.setDownloadIndicatorVisibility(true)
          this.setDownloadIndicatorProgress(0)

          let downloadsRequired = Math.ceil(resp.data.n_results / pageSize) // This is ONLY for the progress bar
          console.log('Downloads Needed: ', downloadsRequired)
          let downloadsComplete = 1
          while (lastTotalResults > pageSize) {
            let query = {
              'search_limit': pageSize,
              'include_shape': true,
              'page_start': page_start,
              'boundary_id':
                  {
                    'operation': 'nin',
                    'value': cachedIds
                  },
              'minimum_applicable_date': {
                'operation': 'lt',
                'value': `${this.selectedDatasetDate} 00:00:00`,
                'type': 'datetime'
              },
              'boundary':
                  {
                    'operation': 'intersects',
                    'value': bounds
                  },
              'classification':
                  {
                    'operation': 'in',
                    'value': [this.boundaryType]
                  }
            }
            if (this.selectedDatasetFormat && this.selectedDatasetFormat !== this.boundaryType) {
              query['contained_boundary_class'] = this.selectedDatasetFormat
            }
            Object.assign(query, this.boundaryDateFilters[this.selectedDatasetDate])
            resp = await BoundaryService.boundarySearch(query)
            downloadsComplete += 1
            console.log('Downloaded #', downloadsComplete)
            let newBoundariesData = resp.data.boundaries
            page_start = Math.max(...newBoundariesData.map(x => parseInt(x.boundary_id)))
            lastTotalResults = resp.data.n_results
            boundariesTemp = boundariesTemp.concat(newBoundariesData)
            this.cacheBoundaries(resp.data.boundaries)
            if (this.selectedDataset) {
              this.updateRelativeShading()
            }
            this.loadGeoJSON()
            this.setDownloadIndicatorProgress((downloadsComplete / downloadsRequired) * 100)
          }

          console.log('Downloads Completed: ', downloadsComplete)
          this.downloadClearTimer = setTimeout(() => {
            this.setDownloadIndicatorVisibility(false)
          }, 1000)
        }
        this.map.setOptions({draggable: true});
        console.log('Boundary Cache Size: ', Object.keys(this.boundaries).length)
        // console.log('Fetched Boundaries: ', this.boundaries)
        console.log('Temp: ', boundariesTemp.map(x => boundariesTemp.filter(b => b.boundary_id===x.boundary_id).length))
      }
    },
    /**
     * Boundary fade handler. Called on a timer to add a nice fade-in effect as boundaries are loaded.
     *
     */
    fadeInHandler() {
       this.map.data.forEach((feature) => {
        let opacity = feature.getProperty('opacity')
        if (opacity < 1) {
          // Using 'setProperty' triggers the styling function!
          feature.setProperty('opacity', Math.min(opacity + 0.1, 1))
        }
      })
    },
    /**
     * Recalculate the current min/max dataset values used for relative shading.
     * Called when new boundaries are loaded or a dataset change is made while relative shading is enabled.
     */
    updateRelativeShading () {
      let seenBoundaries = this.getCachedBoundaryIds()
      let values = seenBoundaries.map(boundaryId => this.get_ds_value(boundaryId))
      this.relativeDatasetRange = {
        min: Math.min(...values),
        max: Math.max(...values),
      }
    },
    /**
     * Get a colour for a boundary_id. Normally returns a randomly assigned colour, but will return a fixed colour when
     * a dataset is selected.
     * @param boundary_id
     * @return {number[]|*}
     */
    get_colour(boundary_id) {
      if (this.$store.state.query.selected_dataset && this.$store.state.query.selected_dataset_date) {
        return [0, 50, 200]
      } else {
        if (!Object.hasOwn(this.boundaryColors, boundary_id)) {
          this.boundaryColors[boundary_id] = [Math.random() * 200, Math.random() * 200, Math.random() * 200]
        }
        return this.boundaryColors[boundary_id]
      }
    },
    /**
     * Return the opacity that should be used when filling a boundary. This is usually a fixed value, but when a dataset
     * is selected the shading is based on the boundary's dataset value, compared to the min/max of the dataset (or the
     * min/max of the currently loaded boundaries dataset values, if relative shading is enabled)
     * @param boundary_id
     * @return {number}
     */
    get_fill_opacity(boundary_id) {
      if (this.$store.state.query.selected_dataset) {
        let range = this.relativeDatasetRange.max - this.relativeDatasetRange.min
        let value = this.get_ds_value(boundary_id) - this.relativeDatasetRange.min
        return (value / range) * 0.9 // We don't want 100% opacity.
      } else {
        return 0.3
      }
    },
    /**
     * Lookup a boundary's value in the currently selected dataset.
     * @param boundary_id
     * @return {*|string}
     */
    get_ds_value(boundary_id) {
      // console.log(boundary_id)
      if (this.boundaries[boundary_id]) {
        return this.$store.getters["query/getSelectedDatasetValue"](this.boundaries[boundary_id])
      } else {
        console.log('Failed to find Boundary #', boundary_id)
        return 0
      }
    },
    /**
     * Populate the map with GeoJSON data from the currently loaded boundaries.
     * Also triggers the text overlay to display boundary names.
     * @param clear
     */
    loadGeoJSON (clear=false) {
      if (clear) {
        this.clearShapes()
      }

      for (let boundary_id of Object.keys(this.boundaries)) {
        if (!Object.hasOwn(this.shapes, boundary_id)) {
          this.boundaries[boundary_id].boundary.properties.boundary_id = boundary_id
          this.boundaries[boundary_id].boundary.properties.opacity = 0 // this.get_fill_opacity(boundary_id)
          this.boundaries[boundary_id].boundary.properties.visible = 1
          let featureArray = this.map.data.addGeoJson(this.boundaries[boundary_id].boundary, {idPropertyName: 'boundary_id'})

          this.shapes[boundary_id] = featureArray
        } else {
          // This forces the styling to refresh, which seems to reduce shading artifacts
          this.shapes[boundary_id].forEach(f => this.map.data.revertStyle(f))
        }
      }
      if (this.textOverlay) {
        this.textOverlay.updateBoundaryNames()
      }
    },
    /**
     * Add a POI marker to the map.
     * @param poiData
     */
    addPOIMarker(poiData) {
      const position = { lat: poiData.latitude, lng: poiData.longitude }
     this.poiMarkers.push({
        position: position,
        map: this.map,
        title: poiData.tooltip
      })
    },
    /**
     * Load POI Data
     * @param poiData
     */
    loadPOIs(poiData) {
      if (poiData) {
        this.poiMarkers = []
        poiData.forEach(poi => this.addPOIMarker(poi))
      }
    },
    togglePOIs() {
      this.showPOIs = !this.showPOIs
    },
    /**
     * Click event handler for a boundary (map feature)
     * Creates an info window on the map which display data on the boundary.
     * @param event
     */
    clickBoundary (event) {
      let boundary_id = event.feature.getProperty('boundary_id')
      let boundary = this.boundaries[boundary_id]
      if (Object.hasOwn(this.infoWindows, boundary_id)) {
        this.infoWindows[boundary_id].setPosition(event.latLng)
        this.infoWindows[boundary_id].open({map: this.map})
      } else {

        let containedBoundaries = ''
        if (Object.hasOwn(boundary, 'contained_boundary_names')) {
          containedBoundaries =
              `<div class="explorer-infowindow-row">` +
              `<div>Contained Boundaries (${this.selectedDatasetFormat}): </div>` +
              `<div class="font-italic">${boundary.contained_boundary_names.length}</div>` +
              `</div>`
        }

        let contentString = '<div id="content">' +
            // '<div id="siteNotice">' +
            // "</div>" +
            `<h2 id="firstHeading" class="firstHeading">${boundary.name}</h2>` +
            '<div class="explorer-infowindow-content">' +
            '<div class="explorer-infowindow-row">' +
            `<div>State: </div>` +
            `<div>${boundary.state_name}</div>` +
            '</div>' +
            '<div class="explorer-infowindow-row">' +
            `<div>Classification: </div>` +
            `<div>${boundary.classification}</div>` +
            '</div>' +
            '<div class="explorer-infowindow-row">' +
            `<div>Valid From: </div>` +
            `<div class="font-italic">${this.formatValidDate(boundary.minimum_applicable_date)}</div>` +
            '</div>' +
            '<div class="explorer-infowindow-row">' +
            `<div>Valid Until: </div>` +
            `<div class="font-italic">${this.formatValidDate(boundary.maximum_applicable_date)}</div>` +
            '</div>' +
            containedBoundaries +
            "</div>" +
            "</div>"
        // eslint-disable-next-line no-undef
        let infoBox = new google.maps.InfoWindow({
          content: contentString,
          position: event.latLng
        });
        infoBox.open({map: this.map})
        this.infoWindows[boundary_id] = infoBox
      }
    },
    /**
     * Text formatter for Boundary validity dates
     * @param date
     * @return {string}
     */
    formatValidDate(date) {
      if (date === null) {
        return 'Forever'
      } else {
        return new Date(date).toLocaleDateString()
      }
    },
    /**
     * Called when boundary type is changed (e.g. SA2 is selected)
     */
    changeBoundaryTypes () {
      console.log(this.boundaryType)
      this.clearBoundaries()
      Object.keys(this.boundaries).forEach(boundary_id => {
        let boundary = this.boundaries[boundary_id]
        let vis = (boundary.classification === this.boundaryType)
        this.shapes[boundary_id].forEach(feature => feature.setProperty('visible', vis))
        if (!vis && this.infoWindows[boundary_id]) {
          this.infoWindows[boundary_id].close()
        }
      })
      this.textOverlay.setBoundaryTypes([this.boundaryType])
      this.mapViewChangeHandler(true)
    },
    /***
     * Download Indicator Functions
     * Note: we're not using Vue's reactivity here, because it won't update while our code is running and it's
     * *slightly* less hacky to update the elements directly, rather than forcing reactivity to run.
     */
    setDownloadIndicatorVisibility(visible) {
      if (visible) {
        this.$refs.downloadOverlay.style.display = 'block'
      } else {
        this.$refs.downloadOverlayInner.style.width = '0'
        this.$refs.downloadOverlay.style.display = 'none'
      }
    },
    setDownloadIndicatorProgress(progressPercentage) {
      this.$refs.downloadOverlayInner.style.width =  progressPercentage + '%'
    },
    clearRelativeDatasetRange() {
      this.relativeDatasetRange = {
        min: 0,
        max: 0
      }
    },
    /**
     * Called when a dataset is selected. Triggers the state actions that fetch the full dataset, then triggers a
     * refresh of the map display to show the name shading.
     *
     * @param datasetId
     * @return {Promise<void>}
     */
    async selectDataset (datasetId, setDates=false, redraw=true) {
      try {
        this.selectedDataset = datasetId
        this.clearRelativeDatasetRange()
        await this.$store.dispatch('query/getSelectedDataset', { datasetId: datasetId })
        // Optionally set the date to the highest date the dataset contains
        if (setDates) {
          await this.selectDatasetDate(this.$store.state.query.selected_dataset.valid_dates.slice(-1)[0], false)
        }
        if (this.selectedDatasetFormat !== this.boundaryType) {
          this.clearBoundaries()
        }
        if (redraw && this.$store.state.query.selected_dataset && this.$store.state.query.selected_dataset_date) {
          this.updateRelativeShading()
          this.loadGeoJSON(true)
        }
        console.log('Selected Dataset: ', this.$store.state.query.selected_dataset)
      } catch (e) {
        console.error(e)
        this.$bvToast.toast('Error, Failed to find Dataset with ID: ' + datasetId, {
          title: 'Failed to find Dataset',
          variant: 'danger',
          toaster: 'b-toaster-top-center'
        })
      }
    },
    /**
     * Clears the dataset selection and triggers a refresh of the map.
     * @return {Promise<void>}
     */
    async clearDataset() {
      this.selectedDataset = null
      await this.$store.commit('query/clearSelectedDataset')
      this.loadGeoJSON(true)
    },
    /**
     * Changes the selected Dateset.
     * @param datasetDate
     * @return {Promise<void>}
     */
    async selectDatasetDate (datasetDate, clearBoundaries=true) {
      console.log('Selected Date: ', datasetDate)
      await this.$store.commit('query/updateSelectedDatasetDate',  datasetDate )
      if (clearBoundaries) {
        this.clearBoundaries()
      }
    },
    /**
     * Toggles hiding SA1 boundary names (they are VERY dense in the cities and hard to read)
     * If disabled, SA1 names are shown on hover.
     */
    toggleSA1Names() {
      this.showSA1Names = !this.showSA1Names
      this.textOverlay.showSA1Names = this.showSA1Names
      this.textOverlay.draw()
    },
    onDatasetDropdown() {
    },
    /**
     * Called when a search result is clicked on.
     * Sets the boundary filter to the same as the search result
     * Pans the view to the center of the search result
     * triggers the map view change handler.
     * @param boundary
     * @return {Promise<void>}
     */
    async selectBoundary(boundary) {
      console.log('Selected Boundary: ', boundary)
      this.setBoundaryTypeFromBoundary(boundary)
      this.changeBoundaryTypes()
      this.map.fitBounds(this.getBoundaryBounds(boundary))
      await this.mapViewChangeHandler(true)
    },
    setBoundaryTypeFromBoundary(boundary) {
      this.boundaryType = boundary.classification
    },
    /**
     * Convert a Reboundary bounding_box into a GMaps Bounds Literal.
     * @param boundary
     * @return {{east: *, south: *, north: *, west: *}}
     */
    getBoundaryBounds(boundary) {
      return {
        east: boundary.bounding_box.max_lon,
        north: boundary.bounding_box.max_lat,
        south: boundary.bounding_box.min_lat,
        west: boundary.bounding_box.min_lon
      }
    },
    async loadBoundaryId(boundaryId) {
      try {
        let resp = await BoundaryService.getBoundary(boundaryId)
        console.log('Get Boundary Result: ', resp)
        if (resp.data) {
          let boundary = resp.data
          this.setBoundaryTypeFromBoundary(boundary)
          this.startBounds = this.getBoundaryBounds(boundary)
        }
      } catch (e) {
        this.$bvToast.toast('Error, Failed to find Boundary with ID: ' + boundaryId, {
          title: 'Failed to find Boundary',
          variant: 'danger',
          toaster: 'b-toaster-top-center'
        })
      }
    },
    /**
     * Show/hide the search results. Hiding them has a small delay to allow the click events for the search results to
     * trigger.
     * @param value
     */
    setShowSearchResults (value) {
      if (value) {
        this.showSearchResults = value
      } else {
        setTimeout(() => {
          this.showSearchResults = value
        }, 250)
      }
    }
  },
    watch: {
      // eslint-disable-next-line no-unused-vars
      geojsondata: function (newGeoData) {
        this.loadGeoJSON()
      },
      poidata: function (newPoiData) {
        this.loadPOIs(newPoiData)
      },
      /**
       * Generate a list of search results when the search text changes
       * Note: This is a 'watch' so we can use the BV debounce functionality.
       * @param newVal
       */
      async boundarySearchText(searchText) {
        if (searchText.length) {
          this.boundarySearchLoading = true
          let query = {
            'include_shape': false,
            'name': {
              'operation': 'ilike',
              'value': `%${searchText}%`
            }
          }
          let resp = await BoundaryService.boundarySearch(query)
          this.boundarySearchResults = resp.data.boundaries

        } else {
          this.boundarySearchResults= []
        }
        this.boundarySearchLoading = false
      }
    }
}
</script>
<style scoped>
.map {
  background-color: lightgrey;
  width: 100%;
  height: 100%;
}

.classification-control {
  background: white;
  width: 150px;
  position: absolute;
  top: 180px;
  left: 10px;
  text-align: center;
  z-index: 5;
  color: #e84235;
  font-size: 2em;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.poi-control {
  background: white;
  width: 50px;
  position: relative;
  top: -72px;
  left: 10px;
  text-align: center;
  z-index: 5;
  color: #e84235;
  font-size: 2em;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.poi-icon:focus {
  border: none;
  outline: none !important;
}

.poi-control:hover {
  color: #b71e00;
}

.poi-disabled {
  color: #6c6c6c;
}

.poi-disabled:hover {
  color: #545454;
}

.dataset-control {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  background: white;
  padding: 10px;
  min-width: 350px;
  max-width: 500px;
  width: 30vw;
  height: 48px;
  position: absolute;
  bottom: 25px;
  left: 10px;
  text-align: center;
  z-index: 5;
  color: #e84235;
  font-size: 2em;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.dataset-selected {
  padding: 15px;
  //min-width: 350px;
  //max-width: 500px;
  //width: 30vw;
  //height: 48px;
  position: absolute;
  bottom: 75px;
  left: 10px;
  //text-align: center;
  z-index: 1;
  background: #c3d9f3;
  //border-radius: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.dataset-selected-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  color: #152c6e;
  font-size: 0.9em;
  padding-right: 30px;
}

.dataset-selected-heading {
  font-size: 1.4em;
  font-weight: 600;
  color: #152c6e;
  border-bottom: 1px solid #152c6e;
  margin-bottom: 5px;
}

.dataset-selected-label {
  font-weight: 500;
  font-size: 1.2em;
}

.dataset-control-close {
  position: absolute;
  right: 5px;
  top: 0;
  font-size: 1.2em;
}

.dataset-control-close:hover {
  color: #8d8d8d;
}

.dataset-select {
  width: calc(100% - 50px);
  /*min-width: 160px;*/
  margin-left: 10px;
}

.dataset-date-select {
  width: calc(25% - 25px);;
  /*min-width: 160px;*/
  margin-left: 10px;
}

.cb-custom {
  background: transparent;
}

.cb-custom > * {
  border-radius: 0;
  background: black;
}

.download-overlay {
  display: none;
  position: absolute;
  z-index: 10;
  top: 94px;
  width: 100%;
  height: 5px;
  background: white;
  overflow: hidden;
  -webkit-transition: fadein 2s ease-in-out;
  -moz-transition: fadein 2s ease-in-out;
  -o-transition: fadein 2s ease-in-out;
  transition: fadein 2s ease-in-out;
}

.download-overlay-inner {
  background: #2b6baa;
  width: 0;
  height: 5px;
  -webkit-transition: width 500ms ease-in-out;
  -moz-transition: width 500ms ease-in-out;
  -o-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
}

.settings-drop-down {
  width: 15em;
}

.dataset-list {
  height: 50vh;
  overflow-y: auto;
}

.search-icon {
  color: #4f4f4f;
}

.search-control {
  display: flex;
  position: absolute;
  top: 104px;
  left: 185px;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  background: white;
  padding: 10px;
  min-width: 350px;
  max-width: 500px;
  width: 30vw;
  height: 40px;
  text-align: center;
  z-index: 1;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.search-results {
  display: flex;
  position: absolute;
  top: 144px;
  left: 236px;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: nowrap;
  background: white;
  padding: 10px;
  min-width: 350px;
  max-width: 500px;
  z-index: 3;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  max-height: 80vh;
  overflow-y: auto;
}

.search-result-icon {
  color: #014c7f;
}

.loading-widget {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.boundary-type-options {
  background: transparent;
  label {
    background: transparent;
  }
}

.data-mismatch-icon {
  color: #be7300;
}

.dataset-selected-icon {
  font-size: 1.2em;
  color: #014c7f;
}

</style>

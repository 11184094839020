<template>
  <div class="boundary-explorer">
    <ExplorerMap></ExplorerMap>
  </div>
</template>

<script>
import colours from '@/translation/cluster_colour_codes.js';
import ExplorerMap from "../components/ExplorerMap";


export default {
  name: 'QueryDashboard',
  components: {
    ExplorerMap
  },
  data() {
    return {
      loading: true,
      dashboard: null,
      content: '',
      pageConfig: {},
      dataset: null,
      clusterBoundaries: [],
      schema: null,
      showMap: true,
      showReportName: true,
      showTitle: true,
      current_cluster_id: 0,
      dashboard_disabled: false,
      colours,
      // Dataset selection. See the computed value dataset_options
      datasets_selected: {},
      errorMessage: null,
      client: '',
      // Default Stats Config
      // TODO - Maybe move this somewhere else?
      contents: null,
      poiData: null
    };
  },
  computed: {
  },
  methods: {

  },
  async mounted() {
  },
  watch: {
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      this.current_cluster_id = newCluster
    }
  }
};
</script>

<style scoped>

.boundary-explorer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0;
  align-content: stretch;
}

.heading {
  /*padding-left: 5px;*/
}

.reboundary-card {
  /*margin: ;*/
}


.card-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fullwidth {
  margin: 0;
  border: 0;
  width: 100%;
  background-color: #e3e3e4;
  background: linear-gradient(180deg, #e3e3e4 0%, #c7c7c7 100%);
}

.results_parent {
  /*width: 80%;*/
  /*min-width: 400pt;*/
  /*margin: auto;*/
  /*padding: 16pt;*/
}

.centred {
  margin: auto;
  text-align: center;
}

.cluster_select_button {
  text-transform: capitalize;
  margin: 4pt;
}

.is_selected_true{
  border: 3px solid rgb(82, 82, 82);
  box-shadow: 2px solid grey;
}

.page-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

</style>
